<template>
    <div class="modal-body">
        <template v-if="loading">
            <i class="fas fa-spinner fa-spin"></i> Even wachten...
        </template>
        <template v-else>
            <div class="row">
                <label for="name" class="col-sm-3 col-form-label">Naam</label>
                <div class="col-sm-6">
                        <input v-model="user.name" class="form-control form-control-sm" id="name"/>
                </div>
            </div>
            <div class="row">
                <label for="phone" class="col-sm-3 col-form-label">Telefoon nr.</label>
                <div class="col-sm-6">
                    <input v-model="user.phone" class="form-control form-control-sm" id="phone"/>
                </div>
            </div>
            <div class="row">
                <label for="email" class="col-sm-3 col-form-label">E-mail</label>
                <div class="col-sm-6">
                    <input v-model="user.email" class="form-control form-control-sm" id="email"/>
                </div>
            </div>
            <div class="row">
                <label for="password" class="col-sm-3 col-form-label">Wachtwoord</label>
                <div class="col-sm-6">
                    <input v-model="user.password" class="form-control form-control-sm" id="password" type="password"/>
                </div>
            </div>
            <div class="row">
                <label for="role_id" class="col-sm-3 col-form-label">Type</label>
                <div class="col-sm-6">
                    <select v-model="user.role_id" class="form-select form-select-sm" id="role_id">   
                    <option value='2'>Gebruiker</option>    
                    <option value='1'>Administrator</option> 
                </select>
                </div>
            </div>
            <div class="row" v-if="user.role_id == 2">
                <label class="col-sm-3 col-form-label">Klant</label>
                <div class="col-sm-6">
                    <select v-model="user.customer_id" class="form-select form-select-sm">   
                    <option :value="null">-</option>    
                    <template v-for="customer in customers" :key="customer.id">
                        <option :value="customer.id">{{customer.name}}</option> 
                    </template> 
                </select>
                </div>
            </div>
        </template>
    </div>
</template>

<script>

    export default {
        name: 'UserFormComponent',
        props: ['itemData', 'customers'],
        data() {
            return {
                user: {
                    'id': null,
                    'name':'',
                    'email':'',
                    'phone':'',
                    'password':'',
                    'role_id': 2,
                    'customer_id': null
                },
                submitted: false,
                loading: false,
                invalid: {}
            }
        },
        watch: {
            itemData: function (val) {
                this.user = val    
            },
        },
        methods: {
            validate(){
                this.invalid = {};
                let valid = true;

                let required = document.querySelectorAll('[required]');

                return valid;
            },
            getData() {
                return this.user
            },
            reset() {
                 this.user = {
                    'id': null,
                    'name':'',
                    'email':'',
                    'phone':'',
                    'password':'',
                    'role_id': 2,
                    'customer_id': null
                },
                this.loading = false
                this.invalid = {}
            },
            setData(data) {
                this.user = data
            },
        },
        mounted () {
            this.reset()
        }
    }
</script>
