<template>
    <Overview 
        :moduleName="'Gebruiker'"
        :moduleNamePlural="'Gebruikers'"
        :sort="'id'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :disableDelete=!isAdmin
        :disableNewButton=!isAdmin
    >
        <template #columns>
            <Column field="name" header="Naam" :sortable="true" style="width: 40%"></Column>
            <Column field="email" header="E-mail" :sortable="true" style="min-width:16rem"></Column>
            <Column header="Admin">
                <template #body="slotProps">
                    {{slotProps.data.role_id == 1 ? 'Ja' : 'Nee'}}
                </template>
            </Column>
            <Column field="customer.name" header="Klant" :sortable="true" style="min-width:16rem"></Column>
        </template>

        <template #form>
            <UserForm ref="ItemForm" :itemData="item" :customers="customers"/>
        </template>
    </Overview>
</template>

<script>
import userService from '@/services/UserService';
import customerService from '@/services/CustomerService';
import Column from 'primevue/column';
import UserForm from '@/components/user/UserFormComponent.vue';
import Overview from '@/components/Overview.vue';
import store from '@/store/user'

export default {
    components: {
        Overview,
        Column,
        UserForm
    },
    computed: {
        isAdmin () {
            return store.getters.isAdmin
        }
    },
    data() {
        return {
            itemService: userService,
            customers: [],
            item: {},
            itemFormRef: null
        }
    },
    methods: {
        getCustomers() {
          customerService.index().then(response => {
            this.customers = response.data
          }).catch(error => {
            console.log('error', error)
          })
        },
    },
    mounted() {
        this.getCustomers();
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>

<style scoped>

    .ms-n5 {
        margin-left: -40px;
    }

    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    a.fa-stack {
        cursor: pointer;
    }

</style>

<style>
.pi-sort-alt:before {
    content: "";
}
</style>